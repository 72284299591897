import React from 'react'

const Profile = () => {
    return (
        <>

            <section id="about" style={{overflow:"hidden"}}>
                <div className="abt-banner">
                    <img src="./assets/img/about.webp" alt="Tubular Battery Manufacturer in India" title="Battery Manufacturer" className="img-fluid" />
                </div>
                <div className="abt-main-body">
                    <div className="container">
                        <h2 className="abt-head">Experience the Trust</h2>
                        <p className="lead abt-top">
                            DB DIXON BATTERY PVT LTD established as a small scale industry scale with state of Art plant for manufacturing of all types of Lead Acid Storage Batteries under the brand name of "DB DIXON"
                        </p>
                        <p>
                            DB Dixon batteries are the fastest-growing brand offering high quality lead acid batteries for automotive, inverter, solar, E-rickshaw and bike applications. Our use of advanced technology, quality management, market research, and customer service has helped us establish DB Dixon as a brand name in the industry.
                        </p>
                        <div className="business-focus">
                            <h3 className="focus-head">Business Focus With <br />Changing Environment</h3> {/*  .focus-head */}
                            <ul>
                                <li>Initial Focus is on Import substitution</li>
                                <li>Appropriate engineering for meeting low volume production</li>
                                <li>Multi product flexibility to meet seasonal changes in demand</li>
                                <li>Adding new engineering products relating <br />to the customer base already established</li>
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="abt-desc">
                                    <h4>Vision</h4>
                                    <p>
                                        To organize India’s Engineering talent into a globally competitive business, whether in manufacturing or in services.
                                    </p>
                                    <p>
                                        Our choice is for Businesses with Technological challenges and / or Engineering intensity.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="abt-desc">
                                    <img src="./assets/img/vision.jpg" alt="Lead Acid Battery Manufacturer" title="vision" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="abt-desc">
                                    <h4>Our Values</h4>
                                    <ul>
                                        <li>Fairness to all</li>
                                        <li>Innovative Spirit</li>
                                        <li>Development of Individuals</li>
                                        <li>Harmonious Coexistence</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="abt-desc">
                                    <h4>Our Strategy</h4>
                                    <ul>
                                        <li>Focus on Technology Intensive Products</li>
                                        <li>Expansion through Strategic Alliances</li>
                                        <li>Adding new engineering products relating to the customer base already established</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="abt-desc">
                                    <h4>Why Choose Us</h4>
                                    <div id="accordion">
                                        <div className="card">
                                            <div className="card-header" id="headingOne">
                                                <h5 className="mb-0">
                                                    Highest Quality Products
                                                    <a className="btn btn-link accordion-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        <i className="fas fa-chevron-circle-down" />
                                                    </a>
                                                </h5>
                                            </div>
                                            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                                <div className="card-body">
                                                    <p>
                                                        Recognition of our pursuit of quality was achieved when we were awarded the ISO 9001.Our Automotive Division is certified as R and TM.
                                                    </p><h5>QUALITY CONTROL PARAMETERS</h5>
                                                    <p>
                                                        The Company is fully equipped with latest equipments for quality control with wet analysis lab and electrical lab which assures quality in each &amp; every process standing from testing of raw materials to the finished product.
                                                    </p>
                                                    <p>
                                                        The equipments which company possesses:
                                                    </p>
                                                    <ul className="qual-param">
                                                        <li>HRD Tester <img src="./assets/img/hrd-tester.jpg" alt="Tubular Inverter Battery" title="hrd-tester" className="img-fluid" /></li>
                                                        <li>Separator testing equipments <img src="./assets/img/battery-separator-tester.jpg" alt="battery separator tester" title="battery separator tester" className="img-fluid" /></li>
                                                        <li>Cold Chamber <img src="./assets/img/cold-chamber.jpg" alt="Tubular Battery Manufacturer in India" title="cold chamber" className="img-fluid" /></li>
                                                        <li>Multi-channel life cycle tester </li>
                                                        <li>Battery line tester </li>
                                                        <li>Optical emission spectrophotometer <img src="./assets/img/optical-spectro.jpg" alt="Lead Acid Battery Manufacturer" title="optical spectro" className="img-fluid" /></li>
                                                        <li>Container testing <img src="./assets/img/container-testing.jpg" alt="E-Rickshaw Batteries Manufacturer in India" title="Container Testing" className="img-fluid" /></li>
                                                        <li>Automatic absorption</li>
                                                        <li>Spectrophotometer <img src="./assets/img/spectrophotometer.jpg" alt="Motorcycle Battery Supplier" title="Spectrophotometer" className="img-fluid" /></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="headingTwo">
                                                <h5 className="mb-0">
                                                    Service
                                                    <a className="btn btn-link collapsed accordion-link" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        <i className="fas fa-chevron-circle-down" />
                                                    </a>
                                                </h5>
                                            </div>
                                            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                                <div className="card-body">
                                                    "Ship fresh inventory within 24 hours from the nearest point to our customer while providing worry-free spent battery disposal." This is the DB DIXON Battery service axiom. Consequently, we ship our products to more than 30 locations with additional facilities and distributors worldwide. Concerning recycling, DB DIXON Battery can assist with proper spent battery disposal through legally approved smelters. That is critical for you because hazardous waste disposal laws place heavy penalties on offenders. In summary, <a href="index.htm">DB DIXON Battery</a> understands the needs of our various markets and delivers quality products whenever and wherever they are needed.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="headingThree">
                                                <h5 className="mb-0">
                                                    Leadership
                                                    <a className="btn btn-link collapsed accordion-link" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        <i className="fas fa-chevron-circle-down" />
                                                    </a>
                                                </h5>
                                            </div>
                                            <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                                <div className="card-body">
                                                    A leader in packaged power technology, DB Dixon Battery Private Limited today is one of the largest storage battery company with widest range of both conventional flooded as well as latest VRLA batteries. Apart from serving the domestic market the company exports batteries which have captured niches in South East Asia and Bangladesh.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="headingFour">
                                                <h5 className="mb-0">
                                                    Commitment
                                                    <a className="btn btn-link collapsed accordion-link" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                        <i className="fas fa-chevron-circle-down" />
                                                    </a>
                                                </h5>
                                            </div>
                                            <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                                <div className="card-body">
                                                    DB DIXON Battery is growing rapidly because we specialize in providing only the highest quality products to emerging sealed battery markets. Moreover, because of our narrow focus on sealed batteries and these niche applications, we can offer you expertise in these areas that very few of our competitors will match. Finally, you can expect our total commitment to keep you safely at the forefront of high-quality battery technology with the most responsive service.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Profile