import React from 'react'
import { Link } from "react-router-dom";


const InverterBatteries = () => {
    return (
        <>
            <section id="category-main">
                <div className="pdt-cat-banner">
                    <img src="./assets/img/tubular.jpg" alt="Inverter Battery manufacture in india" className="img-fluid inverter-banner-img" />
                </div> 
                <div className="pdt-cat-body">
                    <div className="container">
                        <h1 className="pdt-head">Inverter Batteries</h1> {/*  .pdt-head */}
                        <p>With DB Dixon get one of the most trusted <strong>Inverter battery manufacturers in Hyderabad</strong>. That has a special design and is made with high quality raw materials. Our <strong>Inverter Battery</strong> products are designed to withstand harsh climate of India and ensures you optimium power every time.</p>
                        <div className="row inverter-row">
                            <div className="col-md-8 inverter-text-wrap">
                                <h5>Main Features of our <strong style={{ color: 'black' }}>Inverter <a to="index.htm" className="linking">Batteries</a></strong></h5>
                                <ul className="cat-features">
                                    <li>Higher charge acceptance</li>
                                    <li>Specialized lead alloys used resistance to corrosion leading to longer life</li>
                                    <li>Positive plates: pressure die spines for higher reliability and longer life</li>
                                    <li>Very low maintenance</li>
                                    <li>Deep cycle design &amp; very low self discharge</li>
                                    <li>Larger size microporus ceramic vent plugs used to topping up frequently</li>
                                    <li>Separators: polyethylene sleeves used</li>
                                    <li>Tested as per ISO 13369 standard</li>
                                </ul>
                            </div> {/*  .col-md-8 inverter-text-wrap */}
                            <div className="col-md-4 inverter-bat-img-wrap">
                                <div className="pdt-card">
                                    <div className="pdt-top-img">
                                        <img src="./assets/img/tubular-battery.jpg" alt="tubular battery in india" className="img-fluid" />
                                    </div> {/*  .pdt-top-img */}
                                    <div className="pdt-desc">
                                        <h6>Advantages of Our Batteries</h6>
                                        <ul>
                                            <li className="pdt-desc-list">
                                                Full Warranty
                                            </li> {/*  .pdt-desc-list */}
                                            <li className="pdt-desc-list">
                                                Ensuring Quality
                                            </li> {/*  .pdt-desc-list */}
                                            <li className="pdt-desc-list">
                                                Environment Friendly
                                            </li> {/*  .pdt-desc-list */}
                                            <li className="pdt-desc-list">
                                                High charging current ampere ratio as compared to others
                                            </li> {/*  .pdt-desc-list */}
                                        </ul>
                                        <button className="btn btn-default">
                                            <a to="/contact" className="pdt-detail-link">Know More</a>
                                        </button>
                                    </div> {/*  .pdt-desc */}
                                </div> {/*  .pdt-card */}
                            </div> {/*  .col-md-4 inverter-bat-img-wrap */}
                        </div> {/*  .row inverter-row */}
                        <h2 className="pdt-spec-head">Technical Specification</h2>
                        <h3 className="pdt-spec-sub-head">Tubular battery specification chart</h3>
                        <table className="spec-table">
                            <tbody><tr>
                                <th>Model</th>
                                <th>No. of Plates</th>
                                <th>Capacity (Ah)</th>
                                <th>Charge Weight (Kg)</th>
                                <th>Warranty</th>
                            </tr>
                                <tr>
                                    <td>DTT1100</td>
                                    <td>5</td>
                                    <td>110</td>
                                    <td>50.5</td>
                                    <td>30+30 Months</td>
                                </tr>
                                <tr>
                                    <td>DTT1300</td>
                                    <td>5</td>
                                    <td>130</td>
                                    <td>53</td>
                                    <td>30+30 Months</td>
                                </tr>
                                <tr>
                                    <td>DTT1600</td>
                                    <td>5</td>
                                    <td>160</td>
                                    <td>55</td>
                                    <td>30+30 Months</td>
                                </tr>
                                <tr>
                                    <td>DTTU650</td>
                                    <td>7</td>
                                    <td>160</td>
                                    <td>57</td>
                                    <td>36+36 Months</td>
                                </tr>
                                <tr>
                                    <td>DTT1700</td>
                                    <td>5</td>
                                    <td>170</td>
                                    <td>59</td>
                                    <td>36+36 Months</td>
                                </tr>
                                <tr>
                                    <td>DTT1800</td>
                                    <td>7</td>
                                    <td>180</td>
                                    <td>62.5</td>
                                    <td>36+36 Months</td>
                                </tr>
                                <tr>
                                    <td>DTT2000</td>
                                    <td>7</td>
                                    <td>200</td>
                                    <td>64</td>
                                    <td>36+36 Months</td>
                                </tr>
                                <tr>
                                    <td>DTT2200</td>
                                    <td>9</td>
                                    <td>220</td>
                                    <td>67</td>
                                    <td>36+36 Months</td>
                                </tr>
                                <tr>
                                    <td>DTT2400</td>
                                    <td>9</td>
                                    <td>240</td>
                                    <td>70</td>
                                    <td>36+36 Months</td>
                                </tr>
                            </tbody></table>
                        <h5 className="pdt-spec-sub-head">Jumbo battery specification chart</h5>
                        <table className="spec-table">
                            <tbody><tr>
                                <th>Model</th>
                                <th>No. of Plates</th>
                                <th>Capacity (Ah)</th>
                                <th>Charge Weight (Kg)</th>
                                <th>Warranty</th>
                            </tr>
                                <tr>
                                    <td>DJB-1100</td>
                                    <td>7</td>
                                    <td>110</td>
                                    <td>42</td>
                                    <td>24+12 Months</td>
                                </tr>
                                <tr>
                                    <td>DJB-1300</td>
                                    <td>9</td>
                                    <td>130</td>
                                    <td>46</td>
                                    <td>24+12 Months</td>
                                </tr>
                                <tr>
                                    <td>DJB-1600</td>
                                    <td>11</td>
                                    <td>160</td>
                                    <td>51</td>
                                    <td>24+12 Months</td>
                                </tr>
                                <tr>
                                    <td>DJB-2000</td>
                                    <td>13</td>
                                    <td>200</td>
                                    <td>54</td>
                                    <td>24+12 Months</td>
                                </tr>
                            </tbody></table>
                        <div className="instruction-wrap">
                            <p><em>* The given product specifications are for Domestic market only</em></p>
                        </div>
                        <div className="pdt-type-bottom">
                            <h5><Link to="/products"> &lt;&lt; Our Other Products</Link></h5>
                        </div> {/*  .pdt-type-bottom */}
                    </div> {/*  .container */}
                </div> {/*  .pdt-cat-body */}
            </section>

        </>
    )
}

export default InverterBatteries