import React from 'react'
import { Link } from "react-router-dom";


const SolarBatteries = () => {
    return (
        <>
            <section id="category-main">
                <div className="pdt-cat-banner">
                    <img src="./assets/img/solar.jpg" alt="Solar Battery Supplier" className="img-fluid inverter-banner-img" />
                </div> {/*  .pdt-cat-banner */}
                <div className="pdt-cat-body">
                    <div className="container">
                        <h1 className="pdt-head">Solar Batteries</h1> {/*  .pdt-head */}
                        <p>Dixon batteries provide high performance under harsh environment and high ambient temperatures. Made at state of art manufacturing facilities, our <strong>solar battery</strong> efficiently store enrgy and provide optimum power.</p>
                        <div className="row solar-row">
                            <div className="col-md-8 solar-text-wrap">
                                <h5>Main Features of our <strong style={{ color: 'black' }}>Solar Batteries</strong></h5>
                                <ul className="cat-features">
                                    <li>Positive Plates Pressure Die Spines with ZIBO MACHINE for longer life</li>
                                    <li>Specially Designed By For Spv Application</li>
                                    <li>Higher Charger Efficiency. AH Efficiency - In Excess of 90%. WH Efficiency - In excess of 80%</li>
                                    <li>Excess Of 90% WH Efficiency- In Excess Of 80%</li>
                                    <li>Low Rate Of Self Discharge</li>
                                    <li>Special Alloy Used For High Charger Efficiency</li>
                                    <li>3000 Cycle @ 50% DOD Or 50000 Cycle @ 20% DOD</li>
                                    <li>Flat pasted Negative Plates</li>
                                </ul>
                            </div> {/*  .col-md-8 solar-text-wrap */}
                            <div className="col-md-4 solar-bat-img-wrap">
                                <div className="pdt-card">
                                    <div className="pdt-top-img">
                                        <img src="./assets/img/solar-battery.jpg" alt="Solar Battery Manufacturing Company" className="img-fluid" />
                                    </div> {/*  .pdt-top-img */}
                                    <div className="pdt-desc">
                                        <h3 className="text-center">Advantages of Our Batteries</h3>
                                        <ul>
                                            <li className="pdt-desc-list">
                                                Low Maintenance &amp; High Performance
                                            </li> {/*  .pdt-desc-list */}
                                            <li className="pdt-desc-list">
                                                New Improved Grid Design
                                            </li> {/*  .pdt-desc-list */}
                                            <li className="pdt-desc-list">
                                                High Purity Lead oxide for Active Material
                                            </li> {/*  .pdt-desc-list */}
                                            <li className="pdt-desc-list">
                                                New Long Life Separators.
                                            </li>
                                            <li className="pdt-desc-list">
                                                Quick Recovery From Deep Sulphation.
                                            </li>
                                            <li className="pdt-desc-list">
                                                New Aesthetic &amp; Durable Container.
                                            </li>
                                        </ul>
                                        <button className="btn btn-default">
                                            <a to="/contact" className="pdt-detail-link">Know More</a>
                                        </button>
                                    </div> {/*  .pdt-desc */}
                                </div> {/*  .pdt-card */}
                            </div> {/*  .col-md-4 solar-bat-img-wrap */}
                        </div> {/*  .row solar-row */}
                        <h2 className="pdt-spec-head">Technical Specification</h2>
                        <h5 className="pdt-spec-sub-head">Solar Battery specification chart</h5>
                        <table className="spec-table">
                            <tbody><tr>
                                <th>Model</th>
                                <th>Capacity (Ah)</th>
                                <th>Charge Weight (Kg)</th>
                                <th>Warranty</th>
                            </tr>
                                <tr>
                                    <td>DSB20</td>
                                    <td>20</td>
                                    <td>14</td>
                                    <td>36 Months</td>
                                </tr>
                                <tr>
                                    <td>DSB40</td>
                                    <td>40</td>
                                    <td>23.5</td>
                                    <td>36 Months</td>
                                </tr>
                                <tr>
                                    <td>DSB60</td>
                                    <td>60</td>
                                    <td>29</td>
                                    <td>36 Months</td>
                                </tr>
                                <tr>
                                    <td>DSB80</td>
                                    <td>80</td>
                                    <td>38</td>
                                    <td>36 Months</td>
                                </tr>
                                <tr>
                                    <td>DSB100</td>
                                    <td>100</td>
                                    <td>53.5</td>
                                    <td>60 Months</td>
                                </tr>
                                <tr>
                                    <td>DSB150</td>
                                    <td>150</td>
                                    <td>61</td>
                                    <td>60 Months</td>
                                </tr>
                                <tr>
                                    <td>DSB200</td>
                                    <td>200</td>
                                    <td>69</td>
                                    <td>60 Months</td>
                                </tr>
                            </tbody></table>
                        <div className="instruction-wrap">
                            <p><em>* The given product specifications are for Domestic market only</em></p>
                        </div>
                        <div className="pdt-type-bottom">
                            <h5><Link to="/products"> &lt;&lt; Our Other Products</Link></h5>
                        </div> {/*  .pdt-type-bottom */}
                    </div> {/*  .container */}
                </div> {/*  .pdt-cat-body */}
            </section>

        </>
    )
}

export default SolarBatteries