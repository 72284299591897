import React from 'react'
import SliderBanner from '../../component/slidercomponent/SliderBanner'

const HomeBanner = () => {
    return (
        <>
            <section id="main-body">
                <div className="main-wrapper">
                    <section id="top-banner">
                        <div className="top-banner">
                            <div className="banner-wrapper">
                                <div id="">
                                   
                                    <img src="./assets/img/automotive.webp" alt="Battery Manufacturer in India" title="Battery Manufacturer in India" className="img-fluid banner-img" style={{ zIndex: 1 }} />
                                    {/* <img src="./assets/img/banner2.jpg" alt="E-Rickshaw Battery" title="E-Rickshaw Battery" className="img-fluid banner-img" style={{ zIndex: 1 }} />
                                    <img src="./assets/assets/img/banner3.jpg" alt="Tubular Inverter Battery" title="Tubular Inverter Battery" className="img-fluid banner-img" style={{ zIndex: 1 }} />
                                    <img src="./assets/img/banner5.jpg" alt="Lead Acid Battery Manufacture" title="Lead Acid Battery Manufacture" className="img-fluid banner-img active" style={{ zIndex: 3, opacity: '0.616213' }} />
                                    <img src="./assets/img/banner4.jpg" alt="Tubular Battery Manufacturer in India" title="Tubular Battery Manufacturer in India" className="img-fluid banner-img" style={{ zIndex: 2 }} /> */}
                                    {/*<img src="img/banner6.jpg" alt="Tubular Battery" title="Tubular Battery" class="img-fluid banner-img">*/}
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <div className="type-outer">
                        <h1 className="type-inner"><span className="type-bat">E-Rickshaw</span><span className="typed-cursor" style={{ animationIterationCount: 0 }}>|</span> Batteries</h1>
                    </div> */}
                </div>
            </section>


        </>
    )
}

export default HomeBanner