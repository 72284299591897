import React from 'react'

const Management = () => {
    return (
        <>

            <section id="main-body">
                <div className="downloads-wrapper">
                    <div className="container">
                        <div className="team-wrapper">
                            <h3 className="c-color">Our Management</h3> {/*  .c-color */}
                            <div className="row team-row">
                                <div className="col-md-6 team-box">
                                    <div className="team-box-inner">
                                        <div className="card team-card">
                                            <img src="./assets/img/jetender-gandhi.jpg" alt="Tubular Battery Manufacturer in India" title="jetender-gandhi" className="card-img-top" />
                                            <div className="card-body team-card-body">
                                                <h5 className="card-title c-color">Mr. Jetender Gandhi</h5> {/*  .card-title c-color */}
                                                <small className="c-color">Director</small>
                                                <p className="card-text team-card-text">
                                                    Mr. Jetender Gandhi possesses 20 years of experience in production and marketing of batteries. His primary focus has always been on quality and Research &amp; Development
                                                </p> {/*  .card-text team-card-text */}
                                            </div> {/*  .card-body team-card-body */}
                                            <div className="card-footer team-card-footer">
                                                <a href="https://www.facebook.com/jetender.gandhi" target="_blank" className="team-btn">
                                                    <i className="fab fa-facebook-f" />
                                                </a>
                                                <a href="https://www.linkedin.com/in/jetender-gandhi-7000b017/" target="_blank" className="team-btn">
                                                    <i className="fab fa-linkedin-in" />
                                                </a>
                                            </div> {/*  .card-footer team-card-footer */}
                                        </div> {/*  .card team-card */}
                                    </div> {/*  .team-box-inner */}
                                </div> {/*  .col-md-6 team-box */}
                                <div className="col-md-6 team-box">
                                    <div className="team-box-inner">
                                        <div className="card team-card">
                                            <img src="./assets/img/neha-gandhi.jpg" alt="Lead Acid Battery Manufacturer" title="neha gandhi" className="card-img-top" />
                                            <div className="card-body team-card-body">
                                                <h5 className="card-title c-color">Ms. Neha Gandhi</h5> {/*  .card-title c-color */}
                                                <small className="c-color">Director</small>
                                                <p className="card-text team-card-text">
                                                    A Post graduate in accounts, possesses 10 years of experience in accounting.
                                                </p> {/*  .card-text team-card-text */}
                                            </div> {/*  .card-body team-card-body */}
                                            <div className="card-footer team-card-footer">
                                                <a href="https://www.facebook.com/neha.gandhi01" target="_blank" className="team-btn">
                                                    <i className="fab fa-facebook-f" />
                                                </a>
                                                <a href target="_blank" className="team-btn">
                                                    <i className="fab fa-linkedin-in" />
                                                </a>
                                            </div> {/*  .card-footer team-card-footer */}
                                        </div> {/*  .card team-card */}
                                    </div> {/*  .team-box-inner */}
                                </div> {/*  .col-md-6 team-box */}
                                <div className="col-md-6 team-box">
                                    <div className="team-box-inner">
                                        <div className="card team-card">
                                            <img src="./assets/img/vikas-gandhi.jpg" alt="E-Rickshaw Batteries Manufacturer in India" title="vikas gandhi" className="card-img-top" />
                                            <div className="card-body team-card-body">
                                                <h5 className="card-title c-color">Mr. Vikas Gandhi</h5> {/*  .card-title c-color */}
                                                <small className="c-color">Director</small>
                                                <p className="card-text team-card-text">
                                                    Mr. Vikas Gandhi possesses 22 years of experience on production of batteries and lead smelting.
                                                </p> {/*  .card-text team-card-text */}
                                            </div> {/*  .card-body team-card-body */}
                                            <div className="card-footer team-card-footer">
                                                <a href="https://www.facebook.com/vikas.gandhi.712" target="_blank" className="team-btn">
                                                    <i className="fab fa-facebook-f" />
                                                </a>
                                                <a href="https://www.linkedin.com/in/vikas-gandhi-39846521/" target="_blank" className="team-btn">
                                                    <i className="fab fa-linkedin-in" />
                                                </a>
                                            </div> {/*  .card-footer team-card-footer */}
                                        </div> {/*  .card team-card */}
                                    </div> {/*  .team-box-inner */}
                                </div> {/*  .col-md-6 team-box */}
                            </div> {/*  .row team-row */}
                        </div> {/*  .team-wrapper */}
                    </div> {/*  .container */}
                </div> {/*  .downloads-wrapper */}
            </section>

        </>
    )
}

export default Management