import React from 'react'
import { Link } from "react-router-dom";


const EbikeBatteries = () => {
    return (
        <>
            <section id="category-main">
                <div className="pdt-cat-banner">
                    <img src="./assets/img/bike.jpg" alt className="img-fluid inverter-banner-img" />
                </div> {/*  .pdt-cat-banner */}
                <div className="pdt-cat-body">
                    <div className="container">
                        <h1 className="pdt-head">E-Bike Batteries</h1> {/*  .pdt-head */}
                        <p>DB Dixon batteries work longer and delivers more power. We use the latest technology to give you a product that ensures you get the best. Offering you one of the widest range of <strong>E-Bike Battery in Hyderabad</strong>.</p>
                        <div className="row automotive-row e-bike-row">
                            <div className="col-md-4 automotive-text-erap">
                                <div className="per-title">
                                    <h5>Performance Curve</h5>
                                </div>
                                <div className="perf-wrap">
                                    <img src="./assets/img/discharge-curve.jpg" alt className="img-fluid" />
                                </div>
                            </div> {/*  .col-md-8 automotive-text-erap */}
                            <div className="col-md-4 automotive-text-erap">
                                <div className="per-title">
                                    <h5>Cycle service life in relation to the depth of discharge</h5>
                                </div>
                                <div className="perf-wrap">
                                    <img src="./assets/img/cycle-service.jpg" alt className="img-fluid" />
                                </div>
                            </div> {/*  .col-md-8 automotive-text-erap */}
                            <div className="col-md-4 automotive-bat-img-wrap">
                                <div className="pdt-card">
                                    <br />
                                    <div className="pdt-top-img">
                                        <img src="./assets/img/e-bike-battery.jpg" alt className="img-fluid" />
                                    </div> {/*  .pdt-top-img */}
                                    <br />
                                    <br />
                                    <div className="pdt-desc">
                                        <button className="btn btn-default">
                                            <a to="/contact" className="pdt-detail-link">Know More</a>
                                        </button>
                                    </div> {/*  .pdt-desc */}
                                </div> {/*  .pdt-card */}
                            </div> {/*  .col-md-4 automotive-bat-img-wrap */}
                        </div> {/*  .row automotive-row */}
                        <h2 className="pdt-spec-head">Technical Specification of E-Bike Battery</h2>
                        <div className="parameter-chart-cover">
                            <h3 className="pdt-spec-sub-head">E-Bike Battery Parameter Chart</h3>
                            <table className="spec-table" id="parameter-table">
                                <tbody><tr>
                                    <td>Volts</td>
                                    <td colSpan={2}>12V</td>
                                </tr>
                                    <tr>
                                        <td rowSpan={2}>Capacity (25°C)</td>
                                        <td>2 hours rate (11.5A)</td>
                                        <td>23Ah</td>
                                    </tr>
                                    <tr>
                                        <td>10 hours rate (2.6A)</td>
                                        <td>26AH</td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={4}>Capacity Affected By Temperature</td>
                                        <td>40°C</td>
                                        <td>102%</td>
                                    </tr>
                                    <tr>
                                        <td>25°C</td>
                                        <td>100%</td>
                                    </tr>
                                    <tr>
                                        <td>0°C</td>
                                        <td>85%</td>
                                    </tr>
                                    <tr>
                                        <td>-15°C</td>
                                        <td>70%</td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={3}>Residual Capacity (25°C)</td>
                                        <td>Capacity After 3 Months Storage</td>
                                        <td>90%</td>
                                    </tr>
                                    <tr>
                                        <td>Capacity After 6 Months Storage</td>
                                        <td>80%</td>
                                    </tr>
                                    <tr>
                                        <td>Capacity After 12 Months Storage</td>
                                        <td>60%</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={1}>Design cycle service Life</td>
                                        <td colSpan={2}>4 years</td>
                                    </tr>
                                    <tr>
                                        <td rowSpan={2}>Charge (Constant voltage)</td>
                                        <td>Cycle(25°C)</td>
                                        <td>Initial Charging Current Less Than 6.9A Voltage 14.5-14.9V</td>
                                    </tr>
                                    <tr>
                                        <td>Float(25°C)</td>
                                        <td>Charge Voltage 13.6-13.8v</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={1}>Weight (Approx)</td>
                                        <td colSpan={2}>7.1Kg</td>
                                    </tr>
                                </tbody></table>
                            <p>
                                The above are average and data obtained from the first 3 charges discharge cycles. These are not minimum values.
                            </p>
                        </div>
                        <div className="instruction-wrap">
                            <p><em>* The given product specifications are for Domestic market only</em></p>
                        </div>
                        <div className="pdt-type-bottom">
                            <h5><Link to="/products"> &lt;&lt; Our Other Products</Link></h5>
                        </div> {/*  .pdt-type-bottom */}
                    </div> {/*  .container */}
                </div> {/*  .pdt-cat-body */}
            </section>


        </>
    )
}

export default EbikeBatteries