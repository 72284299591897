import React from 'react'
import { Link } from "react-router-dom";


const Footer = () => {
  return (
    <>
      <section id="footer">
        <div className="ft-top">
          <div className="container">
            <div className="row">
              <div className="col-md-4 ft-abt">
                <div className="ft-abt">
                  <h6>About Dixon</h6>
                  <ul>
                    <li className="ft-lists"><Link to="/profile">About Us</Link></li>
                    <li className="ft-lists"><Link to="/awards-certifications">Awards and Certifications</Link></li>
                    <li className="ft-lists"><Link to="/downloads">Downloads</Link></li>
                  </ul>
                </div>{/* ft-abt */}
              </div>
              <div className="col-md-4">
                <div className="ft-products">
                  <h6>Products</h6>
                  <ul>
                    <li className="ft-lists"><Link to="/inverter-batteries">Inverter Batteries</Link></li>
                    <li className="ft-lists"><Link to="/automotive-batteries">Automotive Batteries</Link></li>
                    <li className="ft-lists"><Link to="/bike-batteries">Bike Batteries</Link></li>
                    <li className="ft-lists"><Link to="/solar-batteries">Solar Batteries</Link></li>
                    <li className="ft-lists"><Link to="/auto-motive-green-batteries">Automotive Green Batteries</Link></li>
                    <li className="ft-lists"><Link to="/e-bike-batteries">E-Bike Batteries</Link></li>
                    <li className="ft-lists"><Link to="/e-rickshaw-batteries">E-Rickshaw Batteries</Link></li>
                  </ul>
                </div>{/* ft-products */}
              </div>
              <div className="col-md-4">
                <div className="ft-office">
                  <h6>Corporate Office</h6>
                  <div>
                    <img src="./assets/img/logog.JPEG" alt="Dixon Logo" className="img-fluid" style={{width:"50px", height:"50px"}} />

                  </div>
                  <p>
                    <i className="fas fa-map-marker-alt" />Gv Power Elite
                    <br />
                    # 8-2-686/2/6/1/A, 1st Floor, NBT Nagar, Banjara Hills Road No 12, Hyderabad, Telangana  - 500034.
                    <br />
                    <i className="fas fa-phone" /><a href="tel:+919160601369">+91 9160601369, 8297771369, 8328429393</a>
                    <br />
                    <i className="fas fa-envelope" /><Link to="mailto:info@dixonpowerelite.com">info@dixonpowerelite.com</Link>
                    <br />
                    {/* <i className="fas fa-envelope" /><Link to="mailto:admin@dbdixonbattery.com">admin@dbdixonbattery.com</Link> */}
                  </p>
                  <div className="social-links">
                    <Link to="https://www.facebook.com/dbdixonbattery" target="_blank"><i className="fab fa-facebook-f" /></Link>
                    <Link to="https://twitter.com/dbdixonbattery" target="_blank"><i className="fab fa-twitter" /></Link>
                    <Link to="https://www.instagram.com/dbdixonbattery/" target="_blank"><i className="fab fa-instagram" /></Link>
                  </div>{/* social-links */}
                </div>{/* ft-office */}
              </div>
            </div>
          </div>
        </div>{/* ft-top */}
        <div className="ft-bottom">
          <div className="copyright">
            © DB Dixon 2024. All Right Reserved.
          </div>
          <div className="creation">
            <a href="https://www.dexterous.in/" target="_blank"> Design by Dexterous Technology</a>
          </div>
        </div>{/* ft-bottom */}
      </section>

    </>
  )
}

export default Footer