import React from 'react'

const Export = () => {
    return (
        <>
            <section id="about">
                <div className="abt-banner">
                    <img src="./assets/img/dixon-export-banner.jpg" alt="Solar Battery Manufacturing Company" title="Solar Battery Manufacturing Company" className="img-fluid" />
                </div>
                <div className="export-wrapper section-entry">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="export-header-box">
                                    <h2><span>DB Dixon Battery</span> in Export Market</h2>
                                    <p>Welcome to the Export Hub at DB Dixon, where we take pride in delivering our exceptional battery solutions beyond borders. As a leading battery manufacturer, we extend our commitment to excellence to our valued customers in the United Arab Emirates and Nigeria. Our high-performance batteries are meticulously crafted to meet the diverse energy needs of these dynamic markets, ensuring reliability, sustainability, and cutting-edge technology. At DB Dixon, we strive to power progress by exporting top-quality batteries that cater to a range of applications, from automotive to industrial sectors. Partner with us for a seamless export experience, and let DB Dixon be your trusted source for energy solutions that drive success in the UAE and Nigeria. Embrace a future powered by excellence with DB Dixon.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="background-img-wrapper section-entry">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 mt-4">
                                <div className="txt-box">
                                    <h2>DB Dixon Battery in UAE</h2>
                                    <p>Embark on a journey of energy excellence with DB Dixon's battery exports to the United Arab Emirates. Our high-performance batteries, crafted with precision and innovation, are tailored to meet the sophisticated and dynamic energy demands of the UAE. As a reputable battery manufacturer, DB Dixon ensures that our export-grade batteries adhere to the highest standards of reliability and sustainability. Whether you are in need of powerful automotive batteries or robust industrial solutions, DB Dixon's commitment to excellence powers progress in the UAE. Choose DB Dixon for a seamless export experience, delivering cutting-edge energy solutions to fuel success in the flourishing landscape of the United Arab Emirates.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 mt-4">
                                <div className="txt-box">
                                    <h2>DB Dixon Battery in Nigeria</h2>
                                    <p>DB Dixon is your partner in energy empowerment as we export top-tier batteries to Nigeria. Our batteries, designed for reliability and sustainability, address the diverse energy needs of Nigeria's dynamic market. As a trusted battery manufacturer, DB Dixon ensures that our export-grade batteries deliver dependable performance across various applications, from automotive to industrial sectors. With a commitment to excellence, we strive to power progress and success in Nigeria. Experience a seamless export journey with DB Dixon, your gateway to energy solutions that drive innovation and contribute to a brighter, more sustainable future in Nigeria. Choose DB Dixon for high-quality batteries that empower progress in the heart of Africa.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="commitment-wrapper section-entry">
                    <div className="container-fluid">
                        <h2 className="advantage-head">Advantages</h2>
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
                                <div className="commit-box">
                                    <h4>Cutting-Edge Technology</h4>
                                    <p>DB Dixon batteries stand at the forefront of the energy industry, harnessing cutting-edge technology to provide unparalleled advantages. Our batteries are equipped with the latest innovations, ensuring optimal performance and reliability across various applications. Choose DB Dixon for batteries that embody the forefront of technological advancement, providing a reliable and efficient power source for diverse needs.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
                                <div className="commit-box">
                                    <h4>Diverse Application Capabilities</h4>
                                    <p>One of the significant advantages of DB Dixon batteries lies in their versatility and adaptability across a wide spectrum of applications. Whether it's for automotive, industrial, or renewable energy solutions, DB Dixon batteries are meticulously designed to meet diverse energy needs.From urban infrastructure to remote areas, our batteries deliver consistent and dependable power, showcasing the adaptability that sets DB Dixon apart in the battery manufacturing industry.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
                                <div className="commit-box">
                                    <h4>Sustainable and Eco-Friendly</h4>
                                    <p>DB Dixon is committed to sustainability, and this commitment is reflected in our eco-friendly battery solutions. Our batteries are designed with a focus on minimizing environmental impact, incorporating sustainable materials and production processes. By choosing DB Dixon batteries, customers not only benefit from reliable and high-performance energy solutions but also contribute to a greener and more sustainable future.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Export