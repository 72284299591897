import React from 'react'
import HomeBanner from './home/HomeBanner'
import HomeAbout from './home/HomeAbout'

const Home = () => {
  return (
    <>

        <HomeBanner />
        <HomeAbout />
       
    </>
  )
}

export default Home