import React from 'react'
import { Link } from "react-router-dom";


const HomeAbout = () => {
    return (
        <>

            <section id="main-body">
                <div className="main-wrapper">
                    <div className="welcome">
                        <div className="container">
                            <h1>Welcome to DB Dixon-Battery Manufacturers in Hyderabad</h1>
                            <p>
                                A leader in packaged power technology, <strong>DB Dixon Battery</strong> Private Limited today is one of the
                                largest <strong>Battery Manufacturer In Hyderabad,India</strong> with widest range of both conventional
                                flooded as well as latest <strong>VRLA batteries</strong>. Apart from serving the domestic market the
                                company exports batteries which have captured niches in South East Asia and Bangladesh.
                            </p>
                            <div className="intro">
                                <div className="row card-deck-md-1">
                                    <div className="col-md-6 col-sm-12 intro-card">
                                        <div className="intro-card-inner card">
                                            <img src="./assets/img/Solar.webp" alt="Largest SMF Supplier in " title="Largest SMF Supplier" className="img-fluid" />
                                            {/* <img src="./assets/img/home-intro.jpg" alt="Largest SMF Supplier in " title="Largest SMF Supplier" className="img-fluid" /> */}
                                            <div className="card-body">
                                                <h2 className="card-title">Largest SMF Supplier in Hyderabad</h2>
                                                <p className="card-text">
                                                    DB Dixon Battery Private Limited is one of the largest sealed lead acid <strong><a to="automotive-batteries.html" className="linking">battery</a> suppliers in Hyderabad</strong>,
                                                    Telangana. Due to our focus on sealed batteries and niche applications, we offer you expertise in
                                                    these areas that very few of our competitors will match.
                                                </p>
                                            </div>
                                            <div className="card-footer intro-footer">
                                                <button className="btn btn-default btn-intro">
                                                    <Link to="/products">Know More</Link>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-12 intro-card">
                                        <div className="intro-card-inner card">
                                            <img src="./assets/img/Solar Baattery.png" alt="Solar Battery" title="Battery Supplier" className="img-fluid" />
                                            {/* <img src="./assets/img/range.jpg" alt="Solar Battery" title="Battery Supplier" className="img-fluid" /> */}
                                            <div className="card-body">
                                                <h3 className="card-title">Highest Quality Products</h3>
                                                <p className="card-text">
                                                    DB DIXON <strong>Battery supplies in Hyderabad</strong> only the highest quality batteries
                                                    available anywhere.Our batteries are manufactured to the highest standards to assure that we are
                                                    delivering to you the most effective technology for your unique requirements.
                                                </p>
                                            </div>
                                            <div className="card-footer intro-footer">
                                                <button className="btn btn-default btn-intro">
                                                    <Link to="/products">Know More</Link>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="video-wrapper">
                                <div className="row">
                                    <div className="col-md-8 offset-md-2">
                                        <video controls autoPlay id="myVideo">
                                            <source src="./assets/img/dixon.mp4" type="video/mp4" />
                                        </video>
                                    </div>
                                </div>
                            </div>
                            <div className="testimonial">
                                <h4>Our Satisfied Customers</h4>
                                <div className="row">
                                    <div className="client-card col-md-4">
                                        <div className="client-card-inner">
                                            <h5 className="test-head">Great Partnership</h5>
                                            <p>
                                                We developed a great partnership with Dixon Battery Private Limited and their dedication to their
                                                work is evident in all aspects of their products. We appreciate their attention to quality and great
                                                customer care.
                                            </p>
                                            <div className="client-detail">
                                                <div className="row client-row">
                                                    <div className="col-xs-7 client-desc">
                                                        <small className="client-name">Mr. Rajender</small><br />
                                                        <small className="client-city">Delhi</small>
                                                    </div>
                                                    <div className="col-xs-5 client-img">
                                                        {/* <img src="assets/img/model1.png" alt="" class="img-fluid"> */}
                                                    </div>
                                                </div> {/*  .row */}
                                            </div>{/* client-detail */}
                                        </div>{/* client-card-inner */}
                                    </div>{/* client-card */}
                                    <div className="client-card col-md-4">
                                        <div className="client-card-inner">
                                            <h5 className="test-head">Satisfaction Guaranteed</h5>
                                            <p>
                                                In today's overcrowded market, it is really difficult to find a company that is really genuine in
                                                it's approach and thinks about customers first. With Dixon products I have always got 100%
                                                satisfaction.
                                            </p>
                                            <div className="client-detail">
                                                <div className="row client-row">
                                                    <div className="col-xs-7 client-desc">
                                                        <small className="client-name">Mr. A. Narayanareddy</small><br />
                                                        <small className="client-city">Hyderabad</small>
                                                    </div>
                                                    <div className="col-xs-5 client-img">
                                                        {/* <img src="assets/img/model1.png" alt="" class="img-fluid"> */}
                                                    </div>
                                                </div> {/*  .row */}
                                            </div>{/* client-detail */}
                                        </div>{/* client-card-inner */}
                                    </div>{/* client-card */}
                                    <div className="client-card col-md-4">
                                        <div className="client-card-inner">
                                            <h5 className="test-head">Outstanding Experience</h5>
                                            <p>
                                                Working with Dixon brand has been an outstanding experience. We have more customers coming back,
                                                asking for the product and never settling for anything but Dixon Batteries.
                                            </p>
                                            <div className="client-detail">
                                                <div className="row client-row">
                                                    <div className="col-xs-7 client-desc">
                                                        <small className="client-name">Mr. Mukesh Kumar</small><br />
                                                        <small className="client-city">Warangal</small>
                                                    </div>
                                                    <div className="col-xs-5 client-img">
                                                        {/* <img src="assets/img/model1.png" alt="" class="img-fluid"> */}
                                                    </div>
                                                </div> {/*  .row */}
                                            </div>{/* client-detail */}
                                        </div>{/* client-card-inner */}
                                    </div>{/* client-card */}
                                </div>
                            </div>{/* testimonial */}
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default HomeAbout