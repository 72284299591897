import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import MainLayOut from "./component/MainLayOut";
import { useEffect } from "react";
import Home from "./pages/Home";
import Profile from "./pages/about/Profile";
import Management from "./pages/about/Management";
import Products from "./pages/products/Products";
import InverterBatteries from "./pages/products/InverterBatteries";
import ContactUs from "./pages/contact/ContactUs";
import Blog from "./pages/blog/Blog";
import Export from "./pages/export/Export";
import Infrastructure from "./pages/Infrastructure/Infrastructure";
import AutomotiveBatteries from "./pages/products/AutomotiveBatteries";
import BikeBatteries from "./pages/products/BikeBatteries";
import SolarBatteries from "./pages/products/SolarBatteries";
import AutomotiveGreenBatteries from "./pages/products/AutomotiveGreenBatteries";
import EbikeBatteries from "./pages/products/EbikeBatteries";
import ErickshawBatteries from "./pages/products/ErickshawBatteries";
import AwardsCertifications from "./pages/awards/AwardsCertifications";
import Downloads from "./pages/downloads/Downloads";


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" exact element={<MainLayOut />}>
            <Route index element={<Home />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/management" element={<Management />} />
            <Route path="/products" element={<Products />} />
            <Route path="/inverter-batteries" element={<InverterBatteries />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/export" element={<Export />} />
            <Route path="/infrastructure" element={<Infrastructure />} />
            <Route path="/automotive-batteries" element={<AutomotiveBatteries />} />
            <Route path="/bike-batteries" element={<BikeBatteries />} />
            <Route path="/solar-batteries" element={<SolarBatteries />} />
            <Route path="/auto-motive-green-batteries" element={<AutomotiveGreenBatteries />} />
            <Route path="/e-bike-batteries" element={<EbikeBatteries />} />
            <Route path="/e-rickshaw-batteries" element={<ErickshawBatteries />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/awards-certifications" element={<AwardsCertifications />} />
            <Route path="/downloads" element={<Downloads />} />
           
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
