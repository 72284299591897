import React from 'react'

const Downloads = () => {
    return (
        <>

            <section id="main-body">
                <div className="downloads-wrapper">
                    <div className="container">
                        <h1 className="download-head">Downloads</h1> {/*  .download-head */}
                        <div className="download-wrap">
                            <div className="row download-row">
                                <div className="col-md-6 dl-wrap">
                                    <div className="download-inner-wrap">
                                        <div className="download-inner-cover">
                                            <a href="../assets/img/T-1.jpg" target="_blank">
                                                <img src="../assets/img/T-1.jpg" alt="Battery Manufacturer in India" title="Battery Manufacturer in India" className="img img-fluid" />
                                            </a>
                                        </div> {/*  .download-inner-cover */}
                                        <div className="download-inner-caption">
                                            <h5>Inverter Batteries</h5>
                                        </div> {/*  .download-inner-caption */}
                                    </div> {/*  .download-inner-wrap */}
                                </div> {/*  .col-md-6 */}
                                <div className="col-md-6 dl-wrap">
                                    <div className="download-inner-wrap">
                                        <div className="download-inner-cover">
                                            <a href="../assets/img/A-2.jpg" target="_blank">
                                                <img src="../assets/img/A-2.jpg" alt="Tubular Battery" title="Tubular Battery" className="img img-fluid" />
                                            </a>
                                        </div> {/*  .download-inner-cover */}
                                        <div className="download-inner-caption">
                                            <h5>Automotive Batteries</h5>
                                        </div> {/*  .download-inner-caption */}
                                    </div> {/*  .download-inner-wrap */}
                                </div> {/*  .col-md-6 */}
                                <div className="col-md-6 dl-wrap">
                                    <div className="download-inner-wrap">
                                        <div className="download-inner-cover">
                                            <a href="../assets/img/M-1.jpg" target="_blank">
                                                <img src="../assets/img/M-1.jpg" alt="Tubular Inverter Battery" title="Tubular Inverter Battery" className="img img-fluid" />
                                            </a>
                                        </div> {/*  .download-inner-cover */}
                                        <div className="download-inner-caption">
                                            <h5>Bike Batteries</h5>
                                        </div> {/*  .download-inner-caption */}
                                    </div> {/*  .download-inner-wrap */}
                                </div> {/*  .col-md-6 */}
                                <div className="col-md-6 dl-wrap">
                                    <div className="download-inner-wrap">
                                        <div className="download-inner-cover">
                                            <a href="../assets/img/Solar Baattery.png" target="_blank">
                                                <img src="../assets/img/Solar Baattery.png" alt="E-Rickshaw Battery" title="E-Rickshaw Battery" className="img img-fluid" />
                                            </a>
                                        </div> {/*  .download-inner-cover */}
                                        <div className="download-inner-caption">
                                            <h5>Solar Batteries</h5>
                                        </div> {/*  .download-inner-caption */}
                                    </div> {/*  .download-inner-wrap */}
                                </div> {/*  .col-md-6 */}
                                <div className="col-md-6 dl-wrap">
                                    <div className="download-inner-wrap">
                                        <div className="download-inner-cover">
                                            <a href="../assets/img/A-1.jpg" target="_blank">
                                                <img src="../assets/img/A-1.jpg" alt="Solar Battery" title="Solar Battery" className="img img-fluid" />
                                            </a>
                                        </div> {/*  .download-inner-cover */}
                                        <div className="download-inner-caption">
                                            <h5>Auto Green Batteries</h5>
                                        </div> {/*  .download-inner-caption */}
                                    </div> {/*  .download-inner-wrap */}
                                </div> {/*  .col-md-6 */}
                                <div className="col-md-6 dl-wrap">
                                    <div className="download-inner-wrap">
                                        <div className="download-inner-cover">
                                            <a href="../assets/img/E-3.jpg" target="_blank">
                                                <img src="../assets/img/E-3.jpg" alt="Solar Battery Manufacturing Company" title="Solar Battery Manufacturing Company" className="img img-fluid" />
                                            </a>
                                        </div> {/*  .download-inner-cover */}
                                        <div className="download-inner-caption">
                                            <h5>E-Rickshaw Batteries</h5>
                                        </div> {/*  .download-inner-caption */}
                                    </div> {/*  .download-inner-wrap */}
                                </div> {/*  .col-md-6 */}
                            </div> {/*  .row download-row */}
                        </div> {/*  .download-wrap */}
                    </div> {/*  .container */}
                </div> {/*  .downloads-wrapper */}
            </section>


        </>
    )
}

export default Downloads