import React from 'react'
import { Link } from "react-router-dom";


const AutomotiveBatteries = () => {
    return (
        <>
            <section id="category-main">
                <div className="pdt-cat-banner">
                    <img src="./assets/img/automotive.jpg" alt="Automotive Battery Banner" className="img-fluid inverter-banner-img" />
                </div>
                <div className="pdt-cat-body">
                    <div className="container">
                        <h1 className="pdt-head">Automotive Batteries</h1> {/*  .pdt-head */}
                        <p>DB Dixon batteries work longer and delivers more power. We use the latest technology to give you a product that ensures you get the best. Offering you one of the widest range of <strong>Automotive battery manufacturers in Hyderabad.</strong></p>
                        <div className="row automotive-row">
                            <div className="col-md-8 automotive-text-erap">
                                <h5>Main Features of our <strong style={{ color: 'black' }}>Automotive Batteries</strong></h5>
                                <ul className="cat-features">
                                    <li>We use specialized lead alloys used for ultra low maintenance, high cranking performance &amp; long life</li>
                                    <li>We use SUNLIGHT double side pasting machine for better performance</li>
                                    <li>We use curing chamber for making good quality plates</li>
                                    <li>Designed with higher charge acceptance</li>
                                    <li>Polyethylene envelop seprator with glass mat separators. We have well established in house testing speciality batteries tested as per IS 14257</li>
                                </ul>
                            </div> {/*  .col-md-8 automotive-text-erap */}
                            <div className="col-md-4 automotive-bat-img-wrap">
                                <div className="pdt-card">
                                    <div className="pdt-top-img">
                                        <img src="./assets/img/automotive-battery.jpg" alt className="img-fluid" />
                                    </div> {/*  .pdt-top-img */}
                                    <div className="pdt-desc">
                                        <h6>Advantages of Our Batteries</h6>
                                        <ul>
                                            <li className="pdt-desc-list">
                                                Full Warranty
                                            </li> {/*  .pdt-desc-list */}
                                            <li className="pdt-desc-list">
                                                Ensuring Quality
                                            </li> {/*  .pdt-desc-list */}
                                            <li className="pdt-desc-list">
                                                Eco Friendly
                                            </li> {/*  .pdt-desc-list */}
                                        </ul>
                                        <button className="btn btn-default">
                                            <a to="/contact" className="pdt-detail-link">Know More</a>
                                        </button>
                                    </div> {/*  .pdt-desc */}
                                </div> {/*  .pdt-card */}
                            </div> {/*  .col-md-4 automotive-bat-img-wrap */}
                        </div> {/*  .row automotive-row */}
                        <h2 className="pdt-spec-head">Technical Specification</h2>
                        <h3 className="pdt-spec-sub-head">Automotive battery specification chart</h3>
                        <table className="spec-table">
                            <tbody><tr>
                                <th>Model</th>
                                <th>No. of Plates</th>
                                <th>Capacity (Ah)</th>
                                <th>Charge Weight (Kg)</th>
                                <th>Warranty</th>
                            </tr>
                                <tr>
                                    <td>DAB320</td>
                                    <td>8</td>
                                    <td>32</td>
                                    <td>10</td>
                                    <td>18+12 Months</td>
                                </tr>
                                <tr>
                                    <td>DAB350</td>
                                    <td>9</td>
                                    <td>35</td>
                                    <td>11</td>
                                    <td>18+12 Months</td>
                                </tr>
                                <tr>
                                    <td>DAB600</td>
                                    <td>10</td>
                                    <td>60</td>
                                    <td>16.75</td>
                                    <td>18+12 Months</td>
                                </tr>
                                <tr>
                                    <td>DAB650</td>
                                    <td>11</td>
                                    <td>65</td>
                                    <td>17.5</td>
                                    <td>18+12 Months</td>
                                </tr>
                                <tr>
                                    <td>DAB750</td>
                                    <td>13</td>
                                    <td>75</td>
                                    <td>20</td>
                                    <td>18+12 Months</td>
                                </tr>
                                <tr>
                                    <td>DAB800</td>
                                    <td>14</td>
                                    <td>80</td>
                                    <td>21.5</td>
                                    <td>18+12 Months</td>
                                </tr>
                                <tr>
                                    <td>DAB950</td>
                                    <td>13</td>
                                    <td>95</td>
                                    <td>26.75</td>
                                    <td>18+12 Months</td>
                                </tr>
                                <tr>
                                    <td>DAB1050</td>
                                    <td>15</td>
                                    <td>105</td>
                                    <td>28</td>
                                    <td>18+12 Months</td>
                                </tr>
                                <tr>
                                    <td>DAB1300</td>
                                    <td>17</td>
                                    <td>130</td>
                                    <td>33</td>
                                    <td>18+12 Months</td>
                                </tr>
                                <tr>
                                    <td>DAB1600</td>
                                    <td>19</td>
                                    <td>160</td>
                                    <td>40</td>
                                    <td>18+12 Months</td>
                                </tr>
                                <tr>
                                    <td>DAB1800</td>
                                    <td>25</td>
                                    <td>180</td>
                                    <td>41</td>
                                    <td>18+12 Months</td>
                                </tr>
                                <tr>
                                    <td>DAB2100</td>
                                    <td>27</td>
                                    <td>200</td>
                                    <td>54</td>
                                    <td>18+12 Months</td>
                                </tr>
                            </tbody></table>
                        <div className="instruction-wrap">
                            <p><em>* The given product specifications are for Domestic market only</em></p>
                        </div>
                        <div className="pdt-type-bottom">
                            <h5><Link to="/products"> &lt;&lt; Our Other Products</Link></h5>
                        </div> {/*  .pdt-type-bottom */}
                    </div> {/*  .container */}
                </div> {/*  .pdt-cat-body */}
            </section>

        </>
    )
}

export default AutomotiveBatteries