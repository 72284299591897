import React from 'react'
import { Link } from "react-router-dom";


const AutomotiveGreenBatteries = () => {
    return (
        <>
            <section id="category-main">
                <div className="pdt-cat-banner">
                    <img src="./assets/img/automotive-green.jpg" alt="Automotive Green Batteries Manufacturer in India" className="img-fluid inverter-banner-img" />
                </div> {/*  .pdt-cat-banner */}
                <div className="pdt-cat-body">
                    <div className="container">
                        <h1 className="pdt-head">Automotive Green Batteries</h1> {/*  .pdt-head */}
                        <p>Dixon batteries work longer and delivers more power. We use the latest technology to give you a product that ensures you get the best. Offering you one of the widest range of <strong>Automotive Green Batteries in Hyderabad,India</strong>.</p>
                        <div className="row automotive-row">
                            <div className="col-md-8 automotive-text-erap">
                                <h5>Main Features of our <strong style={{ color: 'black' }}>Automotive Batteries</strong></h5>
                                <ul className="cat-features">
                                    <li>Ca-Ca-Sn Alloy</li>
                                    <li>Expanded Metal Grid</li>
                                    <li>High Cranking power</li>
                                    <li>Durable and Robust design</li>
                                    <li>Suitable for all Weather Conditions</li>
                                    <li>Eco-Friendly</li>
                                    <li><strong>Automotive Green Battery</strong> and Best <strong><a to="index.htm">Battery Manufacturer In India </a></strong></li>
                                </ul>
                            </div> {/*  .col-md-8 automotive-text-erap */}
                            <div className="col-md-4 automotive-bat-img-wrap">
                                <div className="pdt-card">
                                    <div className="pdt-top-img">
                                        <img src="./assets/img/auto-green-battery.jpg" alt="Tubular Inverter Battery" className="img-fluid" />
                                    </div> {/*  .pdt-top-img */}
                                    <div className="pdt-desc">
                                        <h2 className="text-center">Advantages of Our Batteries</h2>
                                        <ul>
                                            <li className="pdt-desc-list">
                                                Full Warranty
                                            </li> {/*  .pdt-desc-list */}
                                            <li className="pdt-desc-list">
                                                Ensuring Quality
                                            </li> {/*  .pdt-desc-list */}
                                            <li className="pdt-desc-list">
                                                Eco Friendly
                                            </li> {/*  .pdt-desc-list */}
                                            <li className="pdt-desc-list">
                                                Higher charging Current Ratio As compared to others
                                            </li> {/*  .pdt-desc-list */}
                                        </ul>
                                        <button className="btn btn-default">
                                            <a to="/contact" className="pdt-detail-link">Know More</a>
                                        </button>
                                    </div> {/*  .pdt-desc */}
                                </div> {/*  .pdt-card */}
                            </div> {/*  .col-md-4 automotive-bat-img-wrap */}
                        </div> {/*  .row automotive-row */}
                        <h3 className="pdt-spec-head">Technical Specification</h3>
                        <h6 className="pdt-spec-sub-head">Automotive Green Battery Specification Chart</h6>
                        <table className="spec-table">
                            <tbody><tr>
                                <th rowSpan={2}>Battery Type</th>
                                <th rowSpan={2}>Ah@20 Hour Rate</th>
                                <th colSpan={3}>Dimension (MM)</th>
                            </tr>
                                <tr>
                                    <th>L</th>
                                    <th>W</th>
                                    <th>H</th>
                                </tr>
                                <tr>
                                    <td>34B20R/L</td>
                                    <td>32 AH</td>
                                    <td>195</td>
                                    <td>1258.5</td>
                                    <td>233</td>
                                </tr>
                                <tr>
                                    <td>38B20R/L</td>
                                    <td>35 AH</td>
                                    <td>195</td>
                                    <td>1258.5</td>
                                    <td>233</td>
                                </tr>
                                <tr>
                                    <td>DB-55B24(S)/L</td>
                                    <td>45 AH</td>
                                    <td>236</td>
                                    <td>127</td>
                                    <td>223</td>
                                </tr>
                                <tr>
                                    <td>DB-60D26R/L</td>
                                    <td>60 AH</td>
                                    <td>260</td>
                                    <td>174</td>
                                    <td>223</td>
                                </tr>
                                <tr>
                                    <td>80D26R/L</td>
                                    <td>70 AH</td>
                                    <td>260</td>
                                    <td>174</td>
                                    <td>223</td>
                                </tr>
                                <tr>
                                    <td>DB-80D31R/L</td>
                                    <td>80 AH</td>
                                    <td>306</td>
                                    <td>173</td>
                                    <td>223</td>
                                </tr>
                                <tr>
                                    <td>DB-95D31R/L</td>
                                    <td>90 AH</td>
                                    <td>306</td>
                                    <td>173</td>
                                    <td>223</td>
                                </tr>
                                <tr>
                                    <td>DB-54434</td>
                                    <td>44 AH</td>
                                    <td>208</td>
                                    <td>173</td>
                                    <td>190</td>
                                </tr>
                                <tr>
                                    <td>DB-55530</td>
                                    <td>55 AH</td>
                                    <td>241</td>
                                    <td>175</td>
                                    <td>190</td>
                                </tr>
                                <tr>
                                    <td>DB-56628</td>
                                    <td>66 AH</td>
                                    <td>276</td>
                                    <td>175</td>
                                    <td>175</td>
                                </tr>
                                <tr>
                                    <td>DB-57412</td>
                                    <td>74 AH</td>
                                    <td>277</td>
                                    <td>175</td>
                                    <td>190</td>
                                </tr>
                            </tbody></table>
                        <div className="instruction-wrap">
                            <p><em>* The given product specifications are for Domestic market only</em></p>
                        </div>
                        <div className="pdt-type-bottom">
                            <h5><Link to="/products"> &lt;&lt; Our Other Products</Link></h5>
                        </div> {/*  .pdt-type-bottom */}
                    </div> {/*  .container */}
                </div> {/*  .pdt-cat-body */}
            </section>


        </>
    )
}

export default AutomotiveGreenBatteries