import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Hadear = () => {

  const [toggle, setToggle] = useState(false);

  const CloseClick = () => {
    setToggle(false);

  };


  const [showAbout, setShowAbout] = useState(false);
  const [showInterior, setShowInterior] = useState(true);
  const [showGallery, setShowGallery] = useState(true);

  const closemenu = () => {
    document.getElementById("mytogglebtn").classList.add("collapsed");
    document.getElementById("menu").classList.remove("in");
  };
  return (
    <nav className="navbar navbar-expand-md navbar-light bg-light fixed-top">
      <div className="container container-nav">
        <Link className="navbar-brand" to="/">
          <img src="./assets/img/db-dixon-logo.png" onClick={closemenu} alt="Dixon Logo" className="img-fluid" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item active">
              <Link className="nav-link" to="/" onClick={closemenu}>
                Home <span className="sr-only">(current)</span>
              </Link>
            </li>
            <li className="nav-item dropdown">
              <Link className="nav-link dropdown-toggle" to="#" id="aboutUsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                About Us
              </Link>
              <div className="dropdown-menu" aria-labelledby="aboutUsDropdown">
                <Link className="dropdown-item" onClick={closemenu} to="/profile">Profile</Link>
                <Link className="dropdown-item" onClick={closemenu} to="/management">Management</Link>
              </div>
            </li>
            <li className="nav-item dropdown">
              <Link className="nav-link dropdown-toggle" to="#" id="productsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Products
              </Link>
              <div className="dropdown-menu" aria-labelledby="productsDropdown">
                <Link className="dropdown-item" onClick={closemenu} to="/inverter-batteries">Inverter Batteries</Link>
                <Link className="dropdown-item" onClick={closemenu} to="/automotive-batteries">Automotive Batteries</Link>
                <Link className="dropdown-item" onClick={closemenu} to="/bike-batteries">Bike Batteries</Link>
                <Link className="dropdown-item" onClick={closemenu} to="/solar-batteries">Solar Batteries</Link>
                <Link className="dropdown-item" onClick={closemenu} to="/auto-motive-green-batteries">Automotive Green Batteries</Link>
                <Link className="dropdown-item" onClick={closemenu} to="/e-bike-batteries">E-Bike Batteries</Link>
                <Link className="dropdown-item" onClick={closemenu} to="/e-rickshaw-batteries">E-Rickshaw Batteries</Link>
              </div>
            </li>
            <li className="nav-item">
              <Link className="nav-link" onClick={closemenu} to="/infrastructure">Infrastructure</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" onClick={closemenu} to="/export">Export</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" onClick={closemenu} to="/contact-us">Contact Us</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Hadear;
