import React from 'react'
import { Link } from "react-router-dom";


const ErickshawBatteries = () => {
    return (
        <>
            <section id="category-main">
                <div className="pdt-cat-banner">
                    <img src="./assets/img/e-richshaw-banner.jpg" alt="E-Rickshaw Battery Banner" className="img-fluid inverter-banner-img" />
                </div> {/*  .pdt-cat-banner */}
                <div className="pdt-cat-body">
                    <div className="container">
                        <h1 className="pdt-head">E-Rickshaw Batteries</h1> {/*  .pdt-head */}
                        <p>DB Dixon batteries work longer and delivers more power. We use the latest technology to give you a product that ensures you get the best. Offering you one of the widest range of <strong>E-Rickshaw Battery Manufacturer in Hyderabad.</strong></p>
                        <div className="row automotive-row">
                            <div className="col-md-8 automotive-text-erap">
                                <h5>Main Features of our <strong style={{ color: 'black' }}>E-Rickshaw Batteries</strong></h5>
                                <ul className="cat-features">
                                    <li>Extra Thick Flat Plate Option</li>
                                    <li>Tubular Plate Option</li>
                                    <li>High Level Of Deep Cycle Ability</li>
                                    <li>Long Service Life</li>
                                    <li>Ultra Low Maintenace</li>
                                    <li>Excellent Cyclic Performance</li>
                                    <li>Quick Charging Acceptance</li>
                                </ul>
                            </div> {/*  .col-md-8 automotive-text-erap */}
                            <div className="col-md-4 automotive-bat-img-wrap">
                                <div className="pdt-card">
                                    <div className="pdt-top-img">
                                        <img src="./assets/img/e-rickshaw-battery.jpg" alt="E-Rickshaw Battery" className="img-fluid" />
                                    </div> {/*  .pdt-top-img */}
                                    <div className="pdt-desc">
                                        <h2>Advantages of Our Batteries</h2>
                                        <ul>
                                            <li className="pdt-desc-list">
                                                Full Warranty
                                            </li> {/*  .pdt-desc-list */}
                                            <li className="pdt-desc-list">
                                                Ensuring Quality
                                            </li> {/*  .pdt-desc-list */}
                                            <li className="pdt-desc-list">
                                                Environment Friendly
                                            </li> {/*  .pdt-desc-list */}
                                            <li className="pdt-desc-list">
                                                High Charging Current Acceptance
                                            </li> {/*  .pdt-desc-list */}
                                        </ul>
                                        <button className="btn btn-default">
                                            <a to="/contact" className="pdt-detail-link">Know More</a>
                                        </button>
                                    </div> {/*  .pdt-desc */}
                                </div> {/*  .pdt-card */}
                            </div> {/*  .col-md-4 automotive-bat-img-wrap */}
                        </div> {/*  .row automotive-row */}
                        <h3 className="pdt-spec-head">Technical Specification</h3>
                        <h5 className="pdt-spec-sub-head">E-Rickshaw battery specification chart</h5>
                        <table className="spec-table">
                            <tbody><tr>
                                <th>Model</th>
                                {/* <th>No. of Plates</th> */}
                                <th>Capacity (Ah)</th>
                                <th>Charge Weight (Kg)</th>
                                <th>Warranty</th>
                            </tr>
                                <tr>
                                    <td>DBER1000</td>
                                    {/* <td></td> */}
                                    <td>100</td>
                                    <td>30</td>
                                    <td>6 Months</td>
                                </tr>
                                <tr>
                                    <td>DBER1200</td>
                                    {/* <td></td> */}
                                    <td>120</td>
                                    <td>33</td>
                                    <td>6 Months</td>
                                </tr>
                                <tr>
                                    <td>DBER1400</td>
                                    {/* <td></td> */}
                                    <td>140</td>
                                    <td>35</td>
                                    <td>6 Months</td>
                                </tr>
                            </tbody></table>
                        <div className="instruction-wrap">
                            <p><em>* The given product specifications are for Domestic market only</em></p>
                        </div>
                        <div className="pdt-type-bottom">
                            <h5><Link to="/products"> &lt;&lt; Our Other Products</Link></h5>
                        </div> {/*  .pdt-type-bottom */}
                    </div> {/*  .container */}
                </div> {/*  .pdt-cat-body */}
            </section>

        </>
    )
}

export default ErickshawBatteries