import React from 'react'
import { Link } from "react-router-dom";


const ContactUs = () => {
  return (
    <>
      <br />
      <br />
      <section className="section-bg" style={{ backgroundImage: 'url(https://i.ibb.co/9p3Cnk9/slider-2.jpg)' }} data-scroll-index={7}>
        <div className="overlay pt-100 pb-100 ">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 d-flex align-items-center">
                <div className="contact-info">
                  <h2 className="contact-title">Get in Touch</h2>
                  <p>Well call you back when you fill out your information *</p>
                  <ul className="contact-info">
                    <li>
                      <div className="info-left">
                        <i className="fas fa-mobile-alt" />
                      </div>
                      <div className="info-right">
                        <h4>+91 9160601369, 8297771369, 8328429393</h4>
                      </div>
                    </li>
                    <li>
                      <div className="info-left">
                        <i className="fas fa-at" />
                      </div>
                      <div className="info-right">
                        <h4>info@dixonpowerelite.com</h4>
                      </div>
                    </li>
                    <li>
                      <div className="info-left">
                        <i className="fas fa-map-marker-alt" />
                      </div>
                      <div className="info-right">
                        <h4>Gv Power Elite <br />
                          # 8-2-686/2/6/1/A, 1st Floor, NBT Nagar, Banjara Hills Road No 12, Hyderabad, Telangana - 500034.</h4>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6 d-flex align-items-center">
              <img src="./assets/img/contact_us.png" alt="Dixon Logo" className="img-fluid" />
              </div>
              {/* <div className="col-lg-6 d-flex align-items-center">
                <div className="contact-form">
                  <form id="contact-form" method="POST"><input type="hidden" name="form-name" defaultValue="contactForm" />
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <input type="text" name="name" className="form-control" id="first-name" placeholder="Enter Your Name *" required="required" />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <input type="email" name="email" className="form-control" id="email" placeholder="Enter Your Email *" required="required" />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <textarea rows={4} name="message" className="form-control" id="description" placeholder="Enter Your Message *" required="required" defaultValue={""} />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <button className="btn-big btn btn-bg">
                          Send Us <i className="fas fa-arrow-right" />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>


    </>
  )
}

export default ContactUs