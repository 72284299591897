import React from 'react'

const AwardsCertifications = () => {
    return (
        <>
            <section id="main-body">
                <div className="awards-wrapper">
                    <div className="container">
                        <h2>Our Awards &amp; Certifications</h2>
                        <div className="awards-wrap">
                            <div className="row awards-row">
                                <div className="col-md-6 awards-box">
                                    <div className="awards-box-inner">
                                        <img src="./assets/img/iso-certificate.jpg" alt="Lead Acid Battery Manufacturer" title="Lead Acid Battery Manufacturer" className="img-fluid" />
                                        <div className="award-title">
                                            <h6>ISO Certified</h6>
                                        </div> {/*  .award-title */}
                                    </div> {/*  .awards-box-inner */}
                                </div> {/*  .col-md-6 awards-box */}
                                <div className="col-md-6 awards-box">
                                    <div className="awards-box-inner">
                                        <img src="./assets/img/ce-logo.jpg" alt="Tubular Inverter Battery" title="Tubular Inverter Battery" className="img-fluid" />
                                        <div className="award-title">
                                            <h6>CE Certified</h6>
                                        </div> {/*  .award-title */}
                                    </div> {/*  .awards-box-inner */}
                                </div> {/*  .col-md-6 awards-box */}
                                <div className="col-md-6 awards-box">
                                    <div className="awards-box-inner">
                                        <img src="./assets/img/nsic-logo.jpg" alt="E-Rickshaw Battery" title="E-Rickshaw Battery" className="img-fluid" />
                                        <div className="award-title">
                                            <h6>NSIC Certified</h6>
                                        </div> {/*  .award-title */}
                                    </div> {/*  .awards-box-inner */}
                                </div> {/*  .col-md-6 awards-box */}
                                <div className="col-md-6 awards-box">
                                    <div className="awards-box-inner">
                                        <img src="./assets/img/cpri-logo.jpg" alt="Solar Battery" title="Solar Battery" className="img-fluid" />
                                        <div className="award-title">
                                            <h6>CPRI Tested</h6>
                                        </div> {/*  .award-title */}
                                    </div> {/*  .awards-box-inner */}
                                </div> {/*  .col-md-6 awards-box */}
                                <div className="col-md-6 awards-box">
                                    <div className="awards-box-inner">
                                        <img src="./assets/img/certificate-iso.jpg" className="img-fluid" />
                                        <div className="award-title">
                                            <h6>ISO</h6>
                                        </div> {/*  .award-title */}
                                    </div> {/*  .awards-box-inner */}
                                </div> {/*  .col-md-6 awards-box */}
                            </div> {/*  .row awards-row */}
                        </div> {/*  .awards-wrap */}
                    </div> {/*  .container */}
                </div> {/*  .awards-wrapper */}
            </section>

        </>
    )
}

export default AwardsCertifications