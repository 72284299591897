import React from 'react'
import { Link } from "react-router-dom";


const Products = () => {
    return (
        <>
            <section id="products-main">
                <div className="pdt-wrapper">
                    <div className="pdt-banner">
                        <img src="./assets/img/all-range.webp" alt="Solar Battery Manufacturing Company" title="All Range" className="img-fluid" />
                    </div>
                    <div className="container">
                        <h className="pdt-head animated slideInLeft">Our Product Range
                            <p>
                                Dixon Battery Private Limited designs, manufactures, markets and sells the widest range of lead acid storage batteries in the world from 2.5Ah to 20,600Ah capacity, to cover the broadest spectrum of applications. Using the latest technological inputs, we manufacture batteries for the automotive, power, telecom, infrastructure projects, computer industries, as well as the railways, mining and defence sectors.
                            </p>
                            <p>
                                Collaborations with South East Asian Countries,Bangladesh ,Indonesia etc. give DIXON a global dimension in manufacturing capability.
                            </p>
                            <p>
                                Whether your application requires extended cycle life, long duration at low rates of discharge, minimal recharge time or maximum high-rate performance, DIXON Battery supplies only the highest quality batteries available anywhere. Our batteries are manufactured to the highest standards to assure that we are delivering to you the most effective technology for your unique requirements. "A battery is just a battery" does not apply here.
                            </p>
                            <div className="main-pdt-row">
                                <div className="pdt-link-icon-wrap">
                                    <div className="pdt-icon">
                                        <Link className="pdt-icon-img" to="/inverter-batteries">
                                            <img src="./assets/img/inverter-a.png" alt="Motorcycle Battery Supplier" title="Inverter" className="img-fluid pdt-icon-img" />
                                            <img src="./assets/img/inverter.png" alt="Tubular Inverter Battery" title="Inverter" className="img-fluid pdt-icon-img" />
                                            {/* <h6 class="pdt-cat-head">Inverter</h6> */}
                                        </Link>
                                        <Link className="pdt-icon-head" to="/inverter-batteries"><h6 className="pdt-cat-head">Tubular Batteries</h6></Link>
                                    </div> {/*  .pdt-icon */}
                                </div> {/*  .pdt-link-icon-wrap */}
                                <div className="pdt-link-icon-wrap">
                                    <div className="pdt-icon">
                                        <Link className="pdt-icon-img" to="/automotive-batteries">
                                            <img src="./assets/img/auto-a.png" alt="E-Rickshaw Batteries Manufacturer in India" title="Auto" className="img-fluid pdt-icon-img" />
                                            <img src="./assets/img/auto.png" alt="Solar Battery Manufacturing Company" title="Auto" className="img-fluid pdt-icon-img" />
                                            {/* <h6 class="pdt-cat-head">Automobile</h6> */}
                                        </Link>
                                        <Link className="pdt-icon-head" to="/automotive-batteries"><h6 className="pdt-cat-head">Automobile Batteries</h6></Link>
                                    </div> {/*  .pdt-icon */}
                                </div> {/*  .pdt-link-icon-wrap */}
                                <div className="pdt-link-icon-wrap">
                                    <div className="pdt-icon">
                                        <Link className="pdt-icon-img" to="/bike-batteries">
                                            <img src="./assets/img/bike-a.png" alt="bike" title="Bike" className="img-fluid pdt-icon-img" />
                                            <img src="./assets/img/bike.png" alt="Battery Manufacturer in India" title="Bike" className="img-fluid pdt-icon-img" />
                                            {/* <h6 class="pdt-cat-head">Bike</h6> */}
                                        </Link>
                                        <Link className="pdt-icon-head" to="/bike-batteries"><h6 className="pdt-cat-head">Bike Batteries</h6></Link>
                                    </div> {/*  .pdt-icon */}
                                </div> {/*  .pdt-link-icon-wrap */}
                                <div className="pdt-link-icon-wrap">
                                    <div className="pdt-icon">
                                        <Link className="pdt-icon-img" to="/solar-batteries">
                                            <img src="./assets/img/solar-a.png" alt="Solar" title="Solar" className="img-fluid pdt-icon-img" />
                                            <img src="./assets/img/solar.png" alt="Solar" title="solar" className="img-fluid pdt-icon-img" />
                                            {/* <h6 class="pdt-cat-head">Solar</h6> */}
                                        </Link>
                                        <Link className="pdt-icon-head" to="/solar-batteries"><h6 className="pdt-cat-head">Solar Batteries</h6></Link>
                                    </div> {/*  .pdt-icon */}
                                </div> {/*  .pdt-link-icon-wrap */}
                                <div className="pdt-link-icon-wrap">
                                    <div className="pdt-icon">
                                        <Link className="pdt-icon-img" to="/auto-motive-green-batteries">
                                            <img src="./assets/img/auto-green-a.png" alt="Auto Green" title="Auto Green" className="img-fluid pdt-icon-img" />
                                            <img src="./assets/img/auto-green.png" alt="Auto Green" title="Auto Green" className="img-fluid pdt-icon-img" />
                                            {/* <h6 class="pdt-cat-head">Solar</h6> */}
                                        </Link>
                                        <Link className="pdt-icon-head" to="/auto-motive-green-batteries"><h6 className="pdt-cat-head">Automotive Green Batteries</h6></Link>
                                    </div> {/*  .pdt-icon */}
                                </div> {/*  .pdt-link-icon-wrap */}
                            </div> {/*  .main-pdt-row */}
                        </h></div> {/*  .container */}
                </div> {/*  .pdt-wrapper */}
            </section>


        </>
    )
}

export default Products