import React from 'react'

const Infrastructure = () => {
    return (
        <>

            <section id="infra">
                {/* <div className="video-wrapper">
                    <div className="video-inner" data-vide-bg="./assets/img/dixon-video" data-vide-options="loop: true, muted: true, position: 0% 0%">
                    </div> 
                </div>  */}
                <div className="infrastructure">
                    <div className="infra-wrapper">
                        <div className="container">
                            <h1 className="infra-head">Our Manufacturing Strength</h1> {/*  .infra-head */}
                            <p>
                                On the strength of our robust and modern infrastructure, we at Dixon Battery are Serving both commercial and private sectors effectively. Our state of art plant is equipped with automatic machinery to improve quality and increase production. Machines are technologically advanced with user friendly control panels to do jobs as per clients requirements.
                            </p>
                            <p>
                                Our tubular filling machines are having automatic cleaning system and dust collector system to protect the environment. The acid filling machines are equipped with design to ensure their is no residual metal component to enhance performance and quality.
                            </p>
                            <p className="infra-lead">
                                It is on back of these system we are able to justify our tagline: <br /> <span>We define power technology</span>
                            </p>
                            <div className="row infra-row-1">
                                <div className="col-md-6">
                                    <div className="mfg-infra">
                                        <img src="./assets/img/casting-machine.jpg" alt="casting machine" title="casting machine" className="img-fluid mfg-infra-img" />
                                    </div> {/*  .mfg-infra */}
                                </div> {/*  .col-md-6 */}
                                <div className="col-md-6">
                                    <div className="mfg-infra">
                                        <img src="./assets/img/pressure-machine.jpg" alt="pressure machine" title="pressure machine" className="img-fluid mfg-infra-img" />
                                    </div> {/*  .mfg-infra */}
                                </div> {/*  .col-md-6 */}
                            </div> {/*  .row infra-row-1 */}
                            <div className="row infra-row-2">
                                <div className="col-md-6">
                                    <div className="mfg-infra">
                                        <img src="./assets/img/pasting-machine-1.jpg" alt="pasting machine" title="pasting-machine" className="img-fluid mfg-infra-img" />
                                    </div> {/*  .mfg-infra */}
                                </div> {/*  .col-md-6 */}
                                <div className="col-md-6">
                                    <div className="mfg-infra">
                                        <img src="./assets/img/pasting-machine-2.jpg" alt="pasting machine" title="pasting machine" className="img-fluid mfg-infra-img" />
                                    </div> {/*  .mfg-infra */}
                                </div> {/*  .col-md-6 */}
                            </div> {/*  .row infra-row-2 */}
                            <div className="row  infra-row-3">
                                <div className="col-md-6">
                                    <div className="mfg-infra">
                                        <img src="./assets/img/tubular-filling.jpg" alt="tubular filling" title="tubular-filling" className="img-fluid mfg-infra-img" />
                                    </div> {/*  .mfg-infra */}
                                </div> {/*  .col-md-6 */}
                                <div className="col-md-6">
                                    <div className="mfg-infra">
                                        <img src="./assets/img/curing-chambers.jpg" alt="curing chambers" title="curing chambers" className="img-fluid mfg-infra-img" />
                                    </div> {/*  .mfg-infra */}
                                </div> {/*  .col-md-6 */}
                            </div> {/*  .row infra-row-3 */}
                            <div className="row  infra-row-4">
                                <div className="col-md-6">
                                    <div className="mfg-infra">
                                        <img src="./assets/img/battery-assembly-1.jpg" alt="battery assembly" title="battery assembly" className="img-fluid mfg-infra-img" />
                                    </div> {/*  .mfg-infra */}
                                </div> {/*  .col-md-6 */}
                                <div className="col-md-6">
                                    <div className="mfg-infra">
                                        <img src="./assets/img/battery-assembly-2.jpg" alt="battery assembly" title="battery assembly" className="img-fluid mfg-infra-img" />
                                    </div> {/*  .mfg-infra */}
                                </div> {/*  .col-md-6 */}
                            </div> {/*  .row infra-row-4 */}
                            <div className="row  infra-row-5">
                                <div className="col-md-6">
                                    <div className="mfg-infra">
                                        <img src="./assets/img/acid-filling-1.jpg" alt="acid filling" title="acid filling" className="img-fluid mfg-infra-img" />
                                    </div> {/*  .mfg-infra */}
                                </div> {/*  .col-md-6 */}
                                <div className="col-md-6">
                                    <div className="mfg-infra">
                                        <img src="./assets/img/acid-filling-2.jpg" alt="acid-filling" title="acid filling" className="img-fluid mfg-infra-img" />
                                    </div> {/*  .mfg-infra */}
                                </div> {/*  .col-md-6 */}
                            </div> {/*  .row infra-row-5 */}
                        </div> {/*  .container */}
                    </div> {/*  .infra-wrapper */}
                </div> {/*  .infrastructure */}
            </section>

        </>
    )
}

export default Infrastructure